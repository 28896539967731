import { useTranslations } from "@/i18n/utils";
import { Disclosure } from "@headlessui/react";
import { getRelativeLocaleUrl } from "astro:i18n";
import clsx from "clsx";

import Button from "../shared/buttons/button";

const commonHeder =
  "fixed w-full pl-4 p-3 md:px-7 md:py-3 top-0 z-50 max-w-[1440px] bg-white-10 md:bg-white-10 backdrop-blur-3xl md:backdrop-blur-2xl md:top-8 md:rounded-xl";

interface NavBarProps {
  pathname: string;
  lang: "en" | "es";
  classNamesHeader?: string;
}

const BLOB_URL = import.meta.env.PUBLIC_STORAGE_URL;

const LOGO = `${BLOB_URL}/app-images/assets/logotipo/IsoLogoBlanco.svg`;
// const IconSearch = ({ size }: { size: number }) => {
//   return <MagnifyingGlass size={size} className="text-white-70" />;
// };

export default function NavBar({
  pathname,
  classNamesHeader,
  lang,
}: NavBarProps) {
  const t = useTranslations(lang);
  const homeURL = getRelativeLocaleUrl(`${lang}`, "");

  const classNameHeader = clsx(commonHeder, classNamesHeader);

  const handleGetStart = () => {
    const inputElement = document.getElementById("email-waiting-list");
    if (inputElement) {
      inputElement.focus();
      inputElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <header className={classNameHeader}>
      <Disclosure as="nav" className="relative mx-auto" id="navbar">
        <div className="mx-auto w-full">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-3 md:gap-x-10">
              {/* <div className="flex">
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-1 text-white-70 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white-70">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <List
                    aria-hidden="true"
                    className="block h-6 w-6 group-data-[open]:hidden"
                  />
                  <X
                    aria-hidden="true"
                    className="hidden h-6 w-6 group-data-[open]:block"
                  />
                </DisclosureButton>
              </div> */}

              <a href={homeURL} className="relative block w-[120px]">
                <img
                  alt="MavelPoint company"
                  src={LOGO}
                  className="object-contain"
                />
              </a>
              {/* <div className="md:hidden">
                <IconSearch size={20} />
              </div> */}
              {/* <div className="hidden md:block">
                <Input
                  id="search"
                  name="search"
                  type="text"
                  size="sm"
                  placeholder="Search artist"
                  leftIcon={() => <IconSearch size={14} />}
                />
              </div> */}
            </div>
            <div className="flex items-center">
              {/*  <LanguageSelector lang={lang} pathname={pathname} /> */}
              <Button
                buttonType="primary"
                color="lime"
                label={String(t("home.navBar.button"))}
                size="sm"
                className="md:text-buttonBase md:h-10 md:gap-x-2 md:px-4 md:py-2"
                onClick={handleGetStart}
              />
            </div>
          </div>
        </div>
      </Disclosure>
    </header>
  );
}
